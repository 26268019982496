import { userAxios } from '@/services'

const quantificationList = async (perPage) => {
    try {
        return await userAxios.get(`quantifications/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const quantificationCreate = async (data) => {
    try {
        return await userAxios.post('quantifications', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const quantificationUpdate = async (id, data) => {
    try {
        return await userAxios.put(`quantifications/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const quantificationDelete = async (id) => {
    try {
        return await userAxios.delete(`quantifications/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const quantificationPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`quantifications/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const quantificationFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`quantifications/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const quantificationFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`quantifications/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    quantificationList,
    quantificationCreate,
    quantificationUpdate,
    quantificationDelete,
    quantificationPagination,
    quantificationFilter,
    quantificationFilterPagination
}