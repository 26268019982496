import { userAxios } from '@/services'

const goalList = async (perPage) => {
    try {
        return await userAxios.get(`goals/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalCreate = async (data) => {
    try {
        return await userAxios.post('goals', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalUpdate = async (id, data) => {
    try {
        return await userAxios.put(`goals/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalDelete = async (id) => {
    try {
        return await userAxios.delete(`goals/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`goals/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`goals/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`goals/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    goalList,
    goalCreate,
    goalUpdate,
    goalDelete,
    goalPagination,
    goalFilter,
    goalFilterPagination
}