<template>
  <b-sidebar
    id="add-new-goal-sidebar"
    :visible="isAddNewGoalSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-goal-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Type Goal</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Type Goal name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Types -->
          <validation-provider
            #default="validationContext"
            name="Types"
            rules="required"
          >
            <b-form-group label="Types" label-for="types">
              <v-select
                id="types"
                v-model="stateData.goal_types_id"
                :state="getValidationState(validationContext)"
                :options="goalTypesOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Users -->
          <validation-provider
            v-if="stateData.individual && stateData.goal_types_id"
            #default="validationContext"
            :name="labelIndividual"
            rules="required"
          >
            <b-form-group :label="labelIndividual" label-for="user">
              <v-select
                id="user"
                v-model="stateData.individual_id"
                :state="getValidationState(validationContext)"
                :options="individualOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Quantifications -->
          <validation-provider
            #default="validationContext"
            name="Quantifications"
            rules="required"
          >
            <b-form-group label="Quantifications" label-for="Quantification">
              <v-select
                id="Quantification"
                v-model="stateData.quantifications_id"
                :state="getValidationState(validationContext)"
                :options="quantificationsOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Descripcion -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group label="Description" label-for="description">
              <textarea
                name="description"
                id="description"
                class="form-control"
                v-model="stateData.description"
                :state="getValidationState(validationContext)"
              >
              </textarea>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(value) => value.value"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref, watch } from "@vue/composition-api";

import axiosG from "@/services/admin/goals/goals";
import axiosGT from "@/services/admin/goals/goalsTypes";
import axiosQ from "@/services/admin/goals/quantifications";

import axiosU from "@/services/admin/user";
import axiosF from "@/services/admin/facilities";
import axiosR from "@/services/admin/regions";
import axiosRO from "@/services/admin/roles";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewGoalSidebarActive",
    event: "update:is-add-new-goal-sidebar-active",
  },
  props: {
    isAddNewGoalSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateDataTem = {
      name: "",
      goal: 0,
      goal_types_id: "",
      quantifications_id: "",
      individual: "",
      individual_id: "",
      description: "",
      default: false,
      status: true,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const labelIndividual = ref("");
    const individualOptions = ref([]);
    watch(
      () => stateData.value.goal_types_id,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          stateData.value.individual = false;
        }
      }
    );
    watch(
      () => stateData.value.individual,
      (newValue, oldValue) => {
        if (newValue) {
          switch (stateData.value.goal_types_id) {
            case 1:
              axiosF.facilityList(30).then(({ data }) => {
                individualOptions.value = data;
              });
              labelIndividual.value = "Facility";
              break;

            case 2:
              axiosR.regionList(30).then(({ registro: { data } }) => {
                individualOptions.value = data;
              });
              labelIndividual.value = "Region";
              break;

            case 3:
              axiosU.userList(30).then(({ data }) => {
                individualOptions.value = data;
              });
              labelIndividual.value = "User";
              break;

            case 4:
              axiosRO.rolesList(30).then(({ data }) => {
                individualOptions.value = data;
              });
              labelIndividual.value = "Rol";
              break;
          }
        } else {
          stateData.value.individual = false;
          stateData.value.individual_id = null;
          labelIndividual.value = "";
        }
      }
    );

    onMounted(() => {
      loadTypesGoal();
    });

    const goalTypesOptions = ref([]);
    const quantificationsOptions = ref([]);

    const loadTypesGoal = () => {
      axiosGT.goalTypeList(20).then(({ data }) => {
        goalTypesOptions.value = data;
      });
      axiosQ.quantificationList(20).then(({ data }) => {
        quantificationsOptions.value = data;
      });
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      stateData.value.default = true;
      stateData.value.individual = true;
      const datos = {
        ...stateData.value,
      };
      axiosG
        .goalCreate(datos)
        .then((res) => {
          loading.value = false;
          emit("createGoal", res);
          resetuserData();
        })
        .catch(() => {
          loading.value = false;
          emit("createGoal");
        });
    };
    return {
      loading,
      stateData,
      optionsStatus,
      goalTypesOptions,
      quantificationsOptions,
      individualOptions,
      labelIndividual,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
