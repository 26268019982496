<template>
  <div>
    <GoalFilter
      v-if="$can('filter', 'module_system_settings.settings.admin_user.goals')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Goals" iconTable="AwardIcon" />
      <div class="m-1">
        <GoalEdit
          v-if="isEditGoalSidebarActive"
          :is-edit-goal-sidebar-active.sync="isEditGoalSidebarActive"
          :itemEdit="itemEdit"
          @editGoalSuccess="editGoalSuccess"
        />
        <GoalAdd
          :is-add-new-goal-sidebar-active.sync="isAddNewPatientSidebarActive"
          @createGoal="createGoal"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="
              d-flex
              align-items-center
              justify-content-between
              flex-wrap
              mb-1 mb-md-0
            "
          >
            <div>
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t("Entries") }}</label>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewPatientSidebarActive = true"
                :disabled="loading"
                v-if="$can('store', 'module_system_settings.settings.admin_user.goals')"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Goal</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'module_system_settings.settings.admin_user.goals')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            @click="editGoal(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-if="$can('destroy', 'module_system_settings.settings.admin_user.goals')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteGoal(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

import axiosG from "@/services/admin/goals/goals";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import GoalEdit from "./GoalEdit.vue";
import GoalAdd from "./GoalAdd.vue";
import GoalFilter from "./GoalFilter.vue";
import { ref } from "vue-demi";
import NotificationMixin from "@/mixins/NotificationMixin";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    GoalEdit,
    GoalAdd,
    GoalFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [NotificationMixin],
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "id",
        // A column that needs custom formatting
        { key: "name", label: "Name", sortable: true },
        { key: "goal", sortable: true },
        { key: "description" },
        { key: "individual", label: "Individual", sortable: true },
        { key: "status", label: "Status", sortable: true },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditGoalSidebarActive: false,
      isAddNewPatientSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      ailmentFilter: null,
      currentPage: {
        page: 1,
      },
    };
  },
  mounted() {
    this.getGoal();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getGoal();
      } else {
        this.searchFilter(this.ailmentFilter);
      }
    },
  },
  methods: {
    getGoal() {
      axiosG
        .goalList(this.perPage)
        .then(({ data, total, current_page, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editGoal(item) {
      this.isEditGoalSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editGoalSuccess(value) {
      if (value) {
        this.isEditGoalSidebarActive = false;
        this.$refs.toast.success("Type Goal updated successfully");
      } else {
        this.$refs.toast.error("Error updating type goal");
      }
      this.pageChanged(this.currentPage.page);
    },
    createGoal(res) {
      const { type, message } =
        NotificationMixin.methods.notificationCheck(res);

      this.$refs.toast[type](message);
      this.isAddNewPatientSidebarActive = false;
      if (type !== "danger") {
        this.pageChanged(this.currentPage.page);
      }
    },
    deleteGoal(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosG.goalDelete(item.id).then((response) => {
            this.$refs.toast.success("Goal type has been deleted successfully");
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosG
          .goalPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosG
          .goalFilterPagination(this.perPage, page, this.ailmentFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.ailmentFilter = value;
        axiosG
          .goalFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getGoal();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style>
</style>