import { userAxios } from '@/services'

const goalTypeList = async (perPage) => {
    try {
        return await userAxios.get(`goal_types/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalTypeCreate = async (data) => {
    try {
        return await userAxios.post('goal_types', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalTypeUpdate = async (id, data) => {
    try {
        return await userAxios.put(`goal_types/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalTypeDelete = async (id) => {
    try {
        return await userAxios.delete(`goal_types/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalTypePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`goal_types/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalTypeFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`goal_types/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const goalTypeFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`goal_types/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    goalTypeList,
    goalTypeCreate,
    goalTypeUpdate,
    goalTypeDelete,
    goalTypePagination,
    goalTypeFilter,
    goalTypeFilterPagination
}